/*------------------
*   Tab style 01
-------------------*/
.tab-style-1 {
	.nav {
		border-bottom: 1px solid var(--#{$prefix}gray-200);

		@include media-breakpoint-down(md) {
			margin-bottom: 15px;
		}
	}

	.nav-item {
		margin: 0 10px;

		@include media-breakpoint-down(md) {
			margin: 0 5px;
		}

		a {
			color: var(--#{$prefix}dark);
			position: relative;
			padding: 12px 15px;
			text-align: center;
			border-bottom: none;
			width: 100%;
			display: block;

			@include media-breakpoint-down(md) {
				padding: 10px 12px;
			}

			&:after {
				content: "";
				position: absolute;
				bottom: -1px;
				left: 0;
				width: 0;
				height: 1px;
				background: var(--#{$prefix}primary);
				transition: ease-in-out all 0.55s;
			}

			&.active {
				color: var(--#{$prefix}primary);

				&:after {
					width: 100%;
				}
			}
		}

		.icon {
			@include media-breakpoint-up(md) {
				margin-bottom: 8px;
			}

			svg {
				height: 38px;
				width: 38px;

				@include media-breakpoint-down(md) {
					height: 30px;
					width: 30px;
				}
			}
		}

		span {
			font-weight: 700;
			font-size: 16px;
			width: 100%;
			display: block;

			@include media-breakpoint-down(md) {
				display: none;
			}
		}
	}

	.tab-pane {
		padding-top: 20px;
	}
}

/*------------------
*   Tab style 02
-------------------*/
.tab-style-2 {
	.nav {
		margin-bottom: 15px;
		border: none;

		.nav-item {
			margin-right: 18px;

			a {
				padding: 8px 0;
				font-weight: 600;
				color: var(--#{$prefix}dark);
				position: relative;
				display: block;

				&:after {
					content: "";
					position: absolute;
					bottom: 0;
					left: 0;
					width: 0px;
					transition: ease-in-out all 0.55s;
					height: 2px;
					background: var(--#{$prefix}secondary);
				}

				&.active {
					color: var(--#{$prefix}primary);

					&:after {
						width: 100%;
					}
				}
			}
		}
	}
}


/*------------------
*   Tab style 03
-------------------*/
.tab-style-3 {
	.nav {
		background: var(--#{$prefix}white);
		border: none;

		a {
			display: block;
			padding: 15px 10px 18px;
			line-height: normal;
			position: relative;
			color: var(--#{$prefix}body-color);

			&:after,
			&:before {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				transition: ease-in-out all 0.25s;
				margin: auto;
			}

			&:after {
				width: 0px;
				height: 3px;
				background: var(--#{$prefix}secondary);
			}

			&:before {
				width: 20px;
				background: var(--#{$prefix}white);
				border-radius: 50%;
				z-index: 1;
				height: 20px;
				bottom: -8px;
				box-shadow: $box-shadow;
			}

			&.active {
				color: var(--#{$prefix}secondary);

				&:after {
					width: 100%;
				}
			}
		}

		.tab-icon {
			margin-bottom: 10px;

			svg {
				width: 35px;
				height: 35px;
			}
		}

		span {
			font-size: 16px;
			font-weight: 600;

			@include media-breakpoint-down(md) {
				display: none;
			}
		}
	}

	.tab-content {
		padding-top: 30px;
	}
}

/*------------------
*   Tab style 04
-------------------*/
.tab-style-4 {
	.nav {
		margin-bottom: 15px;
		border-bottom: 2px solid var(--#{$prefix}gray-200);

		.nav-item {
			.tb-icon {
				display: inline-block;
				vertical-align: middle;
				font-size: 30px;
				color: var(--#{$prefix}primary);
			}

			span {
				margin-left: 10px;
				vertical-align: middle;

				@include media-breakpoint-down(sm) {
					display: none;
				}
			}

			a {
				padding: 15px 0;
				font-weight: 600;
				color: var(--#{$prefix}dark);
				position: relative;
				display: block;

				&:after {
					content: "";
					position: absolute;
					bottom: -2px;
					left: auto;
					right: 0;
					width: 0px;
					transition: ease-in-out all 0.55s;
					height: 3px;
					background: var(--#{$prefix}primary);
				}

				&.active {
					&:after {
						left: 0;
						right: auto;
						width: 100%;
					}
				}
			}
		}
	}
}

/*------------------
*   Tab style 05
-------------------*/
.tab-style-5 {
	.nav-tabs {
		border: none;

		.nav-item {
			margin: 5px;

			>a {
				position: relative;
				padding: 15px 10px;
				font-weight: 600;
				color: var(--#{$prefix}dark);
				border: none;
				border-radius: 10px;
				background: var(--#{$prefix}white);

				&.active {
					color: var(--#{$prefix}white);
					background: var(--#{$prefix}primary);
				}
			}
		}
	}
}



.tab-style-6 {
	.nav-tabs {
		.nav-item {
			+ .nav-item {
				margin-top: 1.3rem;
			}
		}
		.nav-link {
			border: none;
			border-left: 5px solid transparent;
			background: var(--#{$prefix}gray-200);
			border-radius: $border-radius-lg;
			padding: 1.5rem;
			&.active {
				border-left: 5px solid var(--#{$prefix}dark);
			}
		}
	}
	&.flex-row-reverse {
		.nav-tabs {
			.nav-link {
				border-left: none;
				border-right: 5px solid transparent;
				&.active {
					border-right: 5px solid var(--#{$prefix}dark);
				}
			}
		}
	}
}


.tab-style-7 {
	.nav-tabs {
		border: none;
		.nav-item {
			.nav-link {
				border: 0;
				background: var(--#{$prefix}white);
				padding: 1rem;
				box-shadow: $box-shadow-lg;
				border-radius: $border-radius-lg;
				outline: none;
				color: var(--#{$prefix}body-color);
				.icon-sm {
					background: var(--#{$prefix}primary);
					color: var(--#{$prefix}white);
				}
				&.active {
					background: var(--#{$prefix}primary);
					color: var(--#{$prefix}white);
					h6 {
						color: var(--#{$prefix}white);
					}
					.icon-sm {
						background: var(--#{$prefix}white);
						color: var(--#{$prefix}primary);
					}
				}
			}
		}
	}
}

.tab-style-8 {
	.nav-tabs {
		border: none;
		.nav-item {
			.nav-link {
				border: 0;
				background: none;
				padding: 0 0 20px;
				outline: none;
				width: 100%;
				position: relative;
				color: var(--#{$prefix}body-color);
				&:before {
					content: "";
					position: absolute;
					left: 0;
					bottom: 0;
					right: 0;
					height: 3px;
					background: $gray-300;
				}
				&:after {
					content: "";
					position: absolute;
					left: auto;
					right: 0;
					bottom: 0;
					height: 3px;
					width: 0;
					background: var(--#{$prefix}primary);	
					transition: ease all 0.35s;
				}
				.icon-sm {
					background: var(--#{$prefix}dark);
					color: var(--#{$prefix}white);
				}
				&.active {
					&:after {
						left: 0;
						right: auto;
						width: 100%;
					}
				}
			}
		}
	}
}