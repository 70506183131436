.rd-mailform {
	.has-error {
		.invalid-feedback {
			display: block;
		}
	}
	.snackbars {
		border: 1px solid var(--#{$prefix}gray-200);
		background: var(--#{$prefix}gray-200);
	    border-radius: 3px;
	    font-size: 14px;
	    font-weight: 500;
	    margin-top: 20px;
	    display: none;
	    &.active {
	    	display: block;
	    }
	    &.error {
    	    border: 1px solid rgba(var(--#{$prefix}danger-rgb), 0.05);
		    background: rgba(var(--#{$prefix}danger-rgb), 0.02);
		    color: var(--#{$prefix}danger);
	    }
	    &.success {
    	    border: 1px solid rgba(var(--#{$prefix}success-rgb), 0.05);
		    background: rgba(var(--#{$prefix}success-rgb), 0.02);
		    color: var(--#{$prefix}success);
	    }
		p {
			margin: 0;
			position: relative;
			display: flex;
			align-items: center;
			.s-icon {
				width: 40px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				font-size: 16px;
			}
		}
	}
}


.form-switch {
	.form-check-input {
		height: 1.5em;
	}
}