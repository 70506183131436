@each $color, $value in $theme-colors {
    .btn-#{$color}-light {
        --#{$prefix}btn-bg: rgba(#{to-rgb($value)}, 0.2);
        --#{$prefix}btn-color: rgb(#{to-rgb($value)});
        --#{$prefix}btn-hover-color: #{$white};
        --#{$prefix}btn-hover-bg: #{$value};
        --#{$prefix}btn-focus-shadow-rgb: #{to-rgb($value)};
        --#{$prefix}btn-border-width: 0;
    }
}


.btn {
    &:hover,
    &:focus,
    &:active,
    &.active,
    &.show {
        box-shadow: 0 .25rem .25rem .125rem rgba(var(--#{$prefix}btn-focus-shadow-rgb), .1),
        0 .375rem .75rem -.125rem rgba(var(--#{$prefix}btn-focus-shadow-rgb), .4);
    }
}



.btn-white {
    --bs-btn-border-color: var(--#{$prefix}gray-400);
}


.btn-icon {
    .btn-inner--text {
        &:not(:last-child) {
            margin-right: .5em;
        }

        &:not(:first-child) {
            margin-left: .5em;
        }
    }
}

.btn {

    .spinner-grow-sm,
    .spinner-border-sm {
        vertical-align: middle;
    }

    .btn-inner--text {
        &:not(:last-child) {
            margin-right: .5em;
        }

        &:not(:first-child) {
            margin-left: .5em;
        }
    }
}