.count-down-01 {
    display: flex;
    flex-wrap: wrap;
    > div {
        text-align: center;
        background: var(--#{$prefix}white);
        line-height: 1;
        padding: 10px 0;
        margin: 3px 7px 3px 0px;
        box-shadow: $box-shadow-sm;
        border-radius: $border-radius;
    }
    .count {
        font-size: 20px;
        color: var(--#{$prefix}dark);
        display: inline-block;
        vertical-align: top;
        font-weight: 700;
        width: 100%;
    }
    .title {
        font-size: 14px;
    }
}


