.loading-preloader {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 11111;
	background-color: var(--#{$prefix}white);
	.spinner-border {
		width: 40px;
	    height: 40px;
	    position: absolute;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    margin: auto;
	}
}