/*!
 * Mombo
 * Copyright pxdraft.
*/


// Mombo setup
@import '../vendor/bootstrap/scss/functions';


// Custom setup
@import 'core/custom/user-variables';

// Mombo setup
@import 'core/variables';
@import "core/components/utilitie";


// Bootstrap core
@import '../vendor/bootstrap/scss/bootstrap';



// Vendor
@import "../vendor/bootstrap/icon/bootstrap-icons.css";
@import "../vendor/magnific/magnific-popup.css";
@import "../vendor/swiper/swiper-bundle.min.css";
@import "../vendor/highlight/default.min.css";
@import "../vendor/themify-icons/themify-icons.css";
@import "../vendor/wow/animate.css";




// Header
@import "core/header/header";
@import "core/header/menu";
@import "core/header-top/header-top";


// Footer
@import "core/footer/footer";

// Custom fonts
@import "core/custom/font";


// Components
@import "core/components/base";
@import "core/components/wrapper";
@import "core/components/background";
@import "core/components/breadcrumb";
@import "core/components/spinner";
@import "core/components/buttons";
@import "core/components/card";
@import "core/components/height";
@import "core/components/section";
@import "core/components/section-heading";
@import "core/components/avatar";
@import "core/components/icon";
@import "core/components/image";
@import "core/components/svg";
@import "core/components/link";
@import "core/components/border";
@import "core/components/hover";
@import "core/components/mask";
@import "core/components/text-overflow";
@import "core/components/effect";
@import "core/components/tabs";
@import "core/components/swiper";
@import "core/components/shadow";
@import "core/components/form";
@import "core/components/nav";
@import "core/components/list";
@import "core/components/process-bar";
@import "core/components/portfolio";
@import "core/components/count-down";
@import "core/components/media";
@import "core/components/page-heading";
@import "core/components/device";
@import "core/components/mark";


// Custom styles (User's file)
@import "core/custom/styles";
